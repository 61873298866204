import { NavbarEng, SidebarEng } from "../components/Navigation"
import Header from "../components/Header"
import FooterEng from "../components/FooterEng"

import { Espacio } from "../components/Sociales"

import s from "../styles/sociales.module.css"
import cx from "classnames"

export default function FiestasEng() {
    return (
        <>
            <NavbarEng espLink={ "/actividades/fiestas" }/>
            <SidebarEng espLink={ "/actividades/fiestas" }/>

            <Header routeName="sociales" altName="Mesa de fiesta servida" headerTitle="SOCIAL EVENTS" titleClasses="default"/>

            {/* Títulos */}
            <div className={ s.titulos }>
                <img className={ s.titulos_bg } src="/images/sociales/title_bg.png" srcset="/images/sociales/title_bg.webp"/>
                <div className={ cx(s.titulos_content, 'content') }>
                    <div className={ s.titulos_con }>
                        <div className={ cx(s.titulos_con_title, 'title') }>GREAT EVENTS, GREAT MEMORIES, ONLY AT GRAN ACUARIO MAZATLAN.</div>
                    </div>
                    <div className={ s.titulos_con }>
                        <div className={ s.titulos_con__t1 }>How to make a reservation?</div>
                        <div className={ s.titulos_con__t2 }>If you want to celebrate your event at the Gran Acuario Mazatlan, send us an email with the information and the room you are interested in for a quote.</div>
                        <a href="mailto:eventos@granacuario.com" className={ s.titulos_con__link }>Quote</a>
                    </div>
                    <div></div>
                </div>
            </div>

            {/* Espacios de renta */}
            <div className={ cx(s.espacios, 'section') }>
                <Espacio imgRoute="gran_jardin" nombre="Large Garden" tipo="OUTDOOR SPACE" px="250 A 1200" hrsEng="5"/>
                <Espacio imgRoute="terraza_cormoranes" nombre="Cormoranes Terrace" tipo="OUTDOOR SPACE" px="70" hrsEng="5"/>
                <Espacio imgRoute="roof_garden" nombre="Roof Garden" tipo="OUTDOOR SPACE" px="150" hrsEng="5"/>
                <Espacio imgRoute="salon_laguna" nombre="Laguna Lounge" tipo="A/C SPACE" px="150" hrsEng="5"/>
                <Espacio imgRoute="patio_central" nombre="Central Courtyard" tipo="OUTDOOR SPACE" px="100" hrsEng="5"/>
                <Espacio imgRoute="terraza_laguna" nombre="Laguna Terrace" tipo="OUTDOOR SPACE" px="100" hrsEng="5"/>
                {/*
                <div className={ s.espacios_contacto }>
                    <div className={ s.espacios_contacto__t1 }>¿Cómo reservar?</div>
                    <div className={ s.espacios_contacto__t2 }>Si quieres festejar tu evento en el Gran Acuario Mazatlán, envíanos un correo con la información y el salón que te interesa para cotizar.</div>
                    <a href="mailto:contacto@granacuario.com" className={ s.espacios_contacto__link }>Cotizar</a>
                </div>
                */}
            </div>

            <FooterEng/>
        </>
    )
}