import { Link } from "react-router-dom"
import { NavbarEng, SidebarEng } from "../components/Navigation"
import Header from "../components/Header"
import Regresar from "../components/Regresar"
import { Actividad } from "../components/Actividad"
import FooterEng from "../components/FooterEng"
import grupos from "../styles/grupos.module.css"
import cx from "classnames"

export default function GruposEng() {
    return (
        <>
            <NavbarEng espLink={ "/grupos" }/>
            <SidebarEng espLink={ "/grupos" }/>
            <Header noShadow = { true } routeName="grupos" altName="Personas mirando a pecera" headerTitle="GROUPS" titleClasses="default"/>

            <div className={ grupos.grupos }>
                <div className={ grupos.grupos_content }>
                    <div className={ grupos.grupos_des }>
                        <div className={ grupos.grupos_des__t1 }>Tours and private activities</div>
                        <div className={ grupos.grupos_des__t2 }>The Gran Acuario Mazatlán offers a wide variety of group activities, divided into those for corporate groups and for school groups.</div>
                    </div>
                    <div className={ grupos.grupos_opts }>
                        <div className={ cx(grupos.grupos_opts_el, grupos.escolar ) }>
                            <a target="_blank" href="https://tickets.granacuario.com/983-grupos-y-escuelas"></a>
                            <div className={ grupos.grupos_opts_el__title }>School Group Visits</div>
                            <div className={ grupos.grupos_opts_el__des }>The marine world and its marvelous beings are a fundamental part of our planet. At the Gran Acuario Mazatlán, we aim to foster learning and open our doors to school group visits, to promote the conservation and care of our oceans.</div>
                            {/*
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Visitas escolares<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Durmiendo con tiburones<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Fiestas de cumpleaños<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            */}
                        </div>
                        <div className={ cx(grupos.grupos_opts_el, grupos.empresarial ) }>
                            <a target="_blank" href="https://tickets.granacuario.com/983-grupos-y-escuelas"></a>
                            <div className={ grupos.grupos_opts_el__title }>Group Visits</div>
                            <div className={ grupos.grupos_opts_el__des }>You can tour the aquarium along with your group, where you´ll get to see the species in the garden zone and follow the set path through 25 amazing and colorful habitats that are found in the main building.</div>
                            {/*
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Espacios para eventos<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Auditorio para 200 personas<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Visitar corporativas<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            */}
                        </div>
                    </div>
                    <Regresar link="/eng/" des="Home"/>
                </div>
            </div>

            <FooterEng/>
        </>
    )
}