import { Link } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

import { NavbarEng, SidebarEng } from "../components/Navigation"
import Header from "../components/Header"
import FooterEng from "../components/FooterEng";

import "../styles/global.css"
import "./../styles/exhibiciones.css"

gsap.registerPlugin(ScrollTrigger);

export default function Exhibiciones() {

    { /* Entradas de texto */ }
    const contAnim1 = useRef(null);
    const contAnim2 = useRef(null);
    const contAnim3 = useRef(null);
    const contAnim4 = useRef(null);
    useEffect(() => {
        gsap.from(contAnim1.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.contAnim1' });
    }, []);
    useEffect(() => {
        gsap.from(contAnim2.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.contAnim2' });
    }, []);
    useEffect(() => {
        gsap.from(contAnim3.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.contAnim3' });
    }, []);
    useEffect(() => {
        gsap.from(contAnim4.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.contAnim4' });
    }, []);

    { /* Entradas de bloques */ }
    const exAnim1 = useRef(null);
    const exAnim2 = useRef(null);
    const exAnim3 = useRef(null);
    useEffect(() => {
        gsap.from(exAnim1.current, { duration: 1, y: 40, opacity: 0, scrollTrigger: '.exAnim1' });
    }, []);
    useEffect(() => {
        gsap.from(exAnim2.current, { duration: 1, y: 40, opacity: 0, scrollTrigger: '.exAnim2' });
    }, []);
    useEffect(() => {
        gsap.from(exAnim3.current, { duration: 1, y: 40, opacity: 0, scrollTrigger: '.exAnim3' });
    }, []);

    return (
        <>
            <NavbarEng espLink={ "/habitats" }/>
            <SidebarEng espLink={ "/habitats" }/>
            <Header routeName="habitats" altName="Niño mirando pecera" headerTitle="HABITATS" titleClasses="habitats"/>

            { /* Descripción */ }
            <div className="contenido section">
                <div className="content">
                    <div className="contenido__el">
                        <div className="contenido__el__t1 title contAnim1" ref={ contAnim1 }>THE AQUARIUM,</div>
                        <div className="contenido__el__t1 title contAnim2" ref={ contAnim2 }>OF THE WORLD'S AQUARIUM<img src="/images/habitats/arrow.svg" alt="Arrow"/></div>
                    </div>
                    <div className="contenido__el">
                        <div className="contenido__el__t2 contAnim3" ref={ contAnim3 }>In celebration of the diversity of the Sea of Cortez, called The World's Aquarium by the renowned explorer Jacques-Yves Cousteau, the Gran Acuario Mazatlán presents a one-of-a-kind collection in the entire world, dedicated to the Sea of Cortez.</div>
                        <div className="contenido__el__t2 contAnim4" ref={ contAnim4 }>With almost a million and a half gallons of saltwater among its different exhibits, guests can discover a marvelous array of species belonging to the open sea, corals and coastlines.</div>
                    </div>
                </div>
            </div>

            { /* Exhibiciones elementos */ }
            <div className="exhibiciones">
                <div className="exhibiciones_con">
                    <div className="exhibiciones_con__el exAnim1" ref={ exAnim1 }>
                        <Link to="coast" className="exhibiciones_con__el_btn"></Link>
                        <div className="exhibiciones_con__el_title">Coast<img src="/images/habitats/info.svg" alt="Info icon"/></div>
                        <img src="/images/habitats/costa.png" alt="Costa"/>
                    </div>
                    <Link to="/eng/" className="exhibiciones_con__btn"><img src="/images/habitats/arrow.svg" alt="Arrow"/>Home</Link>
                </div>
                <div className="exhibiciones_con">
                    <div className="exhibiciones_con__el exAnim2" ref={ exAnim2 }>
                        <Link to="land" className="exhibiciones_con__el_btn"></Link>
                        <div className="exhibiciones_con__el_title">Land<img src="/images/habitats/info.svg" alt="Info icon"/></div>
                        <img src="/images/habitats/tierra.png" alt="Tierra"/>
                    </div>
                    <div className="exhibiciones_con__el exAnim3" ref={ exAnim3 }>
                        <Link to="sea" className="exhibiciones_con__el_btn"></Link>
                        <div className="exhibiciones_con__el_title">Sea<img src="/images/habitats/info.svg" alt="Info icon"/></div>
                        <img src="/images/habitats/mar.png" alt="Mar"/>
                    </div>
                </div>
            </div>

            <FooterEng/>
        </>
    )
}