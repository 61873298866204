import { NavbarEng, SidebarEng } from "../components/Navigation"
import Header from "../components/Header"
import { CioDescriptionEng, Element } from "../components/HabitatElements"
import FooterEng from "../components/FooterEng"
import { Link } from "react-router-dom"
import "./../styles/exhibicionElement.css"
import { TierraHabitatsEng } from "../array/habitats"

export default function TierraEng() {
    return (
        <>
            <NavbarEng espLink={ "/habitats/tierra" }/>
            <SidebarEng espLink={ "/habitats/tierra" }/>
            <Header routeName="tierra" altName="Niño mirando pecera" headerTitle="LAND"/>

            <div className="contenido">
                <CioDescriptionEng 
                    des={ [
                        'Even though they seem to be separate worlds, land and sea are strongly tied together.',
                        'The ocean shapes the earth´s landforms, and makes it inhabitable.',
                        'Let´s learn about the land flora and fauna surrounding the Sea of Cortez: its beauty and diversity is incredible!',
                    ] }
                />
                <div className="contenido_ops">
                    {TierraHabitatsEng.map((habitat) => (
                        <Element link={ habitat.link } nombre={ habitat.nombre } key={ habitat.nombre }/>
                    ))}
                </div>
                <div className="contenido_back"><Link to="/eng/habitats"></Link><img src="/images/tierra/arrow.svg" alt="Flecha atrás"/>Habitats</div>
            </div>

            <FooterEng/>

        </>
    )
}