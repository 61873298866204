import { Link } from "react-router-dom"

export default function Footer() {
    return (
        <>
            { /* Footer */ }
            <div className="footer section">
                <div className="content">
                    <div className="footer_con">
                        <div className="footer_con__title title">ABOUT US</div>
                        <a href="https://ciomc.org/#programas" target="_blank" className="footer_con__el">Conservation Programs</a>
                        <a href="mailto:medios@granacuario.com" className="footer_con__el">Media</a>
                        <a href="mailto:rh@granacuario.com" className="footer_con__el">Job Pool</a>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">CONTACT US</div>
                        <div className="footer_con_social">
                            <a href="https://www.facebook.com/granacuariomazatlan" target="_blank" className="footer_con__el"><img src="/images/footer/fb.svg" alt="Facebook Icon"/></a>
                            <a href="https://www.instagram.com/granacuariomazatlan/" target="_blank" className="footer_con__el"><img src="/images/footer/ins.svg" alt="Instagram Icon"/></a>
                            <a href="https://twitter.com/GranAcuarioMzt" target="_blank" className="footer_con__el"><img src="/images/footer/tw.svg" alt="Twitter Icon"/></a>
                            <a href="https://www.google.com/maps/place/Acuario+%22Mar+de+Cort%C3%A9s%22/@23.229808,-106.4287194,17.79z/data=!4m6!3m5!1s0x869f53312f8789e9:0xef0199571d862256!8m2!3d23.2305467!4d-106.4282474!16s%2Fg%2F11k6z2w9ct?coh=164777&entry=tt&shorturl=1" target="_blank" className="footer_con__el"><img src="/images/footer/map.svg" alt="Map Icon"/></a>
                        </div>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">SUPPORT</div>
                        <Link to="/contacto" className="footer_con__el">Contact us</Link>
                        <Link to="/faq" className="footer_con__el">FAQs</Link>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">ADDRESS</div>
                        <a href="https://goo.gl/maps/nrpPfrAL1cZewPbv8" target="_blank" className="footer_con__el">Av. Leonismo Internacional SN, Tellería, 82017 Mazatlán, Sinaloa.</a>
                        {/*<a href="tel:6699817815" className="footer_con__el">Tel. 669 981 7815</a>*/}
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">FOUNDATIONS</div>
                        <div className="footer_con_fund">
                            <a href="https://ciomc.org/" target="_blank" className="footer_con__el"><img src="/images/footer/ciomc.png" alt="CIOMC logo"/></a>
                            <a href="https://www.ciad.mx/" target="_blank" className="footer_con__el"><img src="/images/footer/ciad.png" alt="CIAD logo"/></a>
                            <a href="https://www.icmyl.unam.mx/" target="_blank" className="footer_con__el"><img src="/images/footer/icml.png" alt="ICML logo"/></a>
                        </div>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">SPONSORS</div>
                        <div className="footer_con_fund_dos">
                            <div className="footer_con__el"><img src="/images/footer/jumex_Logo.png" alt="JUMEX logo"/></div>
                            <div className="footer_con__el"><img src="/images/footer/coppel_Logo.png" alt="COPPEL logo"/></div>
                            <div className="footer_con__el"><img src="/images/footer/logo_andamios.png" alt="ANDAMIOS"/></div>
                        </div>
                    </div>
                </div>
            </div>
            { /* Copyright */ }
            <div className="copy section"><div className="content">2023 All Rights Reserved. <a href="/images/tyc.pdf" target="_blank">Terms of use.</a> <a href="/images/reglamento.pdf" target="_blank">Gran Acuario Mazatlán Rules.</a></div></div>
        </>
    )
}