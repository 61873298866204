import { Navbar, Sidebar } from "../components/Navigation"
import Header from "../components/Header"
import { CioDescription, Element } from "../components/HabitatElements"
import Footer from "../components/Footer"
import { Link } from "react-router-dom"
import "./../styles/exhibicionElement.css"
import { TierraHabitats } from "../array/habitats"

export default function Tierra() {
    return (
        <>
            <Navbar engLink={ "/eng/habitats/land" }/>
            <Sidebar engLink={ "/eng/habitats/land" }/>
            <Header routeName="tierra" altName="Niño mirando pecera" headerTitle="TIERRA"/>

            <div className="contenido">
                <CioDescription 
                    des={ [
                        'Aunque parecen mundos separados, tierra y mar están fuertemente relacionados.',
                        'El océano moldea las características de la tierra y la hace habitable.',
                        'Conozcamos la flora y fauna terrestre que rodea el Mar de Cortés: su belleza y diversidad es increíble.',
                    ] }
                />
                <div className="contenido_ops">
                    {TierraHabitats.map((habitat) => (
                        <Element link={ habitat.link } nombre={ habitat.nombre } key={ habitat.nombre }/>
                    ))}
                </div>
                <div className="contenido_back"><Link to="/habitats"></Link><img src="/images/tierra/arrow.svg" alt="Flecha atrás"/>Hábitats</div>
            </div>

            <Footer/>

        </>
    )
}