import { NavbarEng, SidebarEng } from "../components/Navigation"
import Header from "../components/Header"
import { CioDescriptionEng, Element } from "../components/HabitatElements"
import FooterEng from "../components/FooterEng"
import { Link } from "react-router-dom"
import "./../styles/exhibicionElement.css"
import { MarHabitatsEng } from "../array/habitats"

export default function MarEng() {
    return (
        <>
            <NavbarEng espLink={ "/habitats/mar" }/>
            <SidebarEng espLink={ "/habitats/mar" }/>
            <Header routeName="mar" altName="Niño mirando pecera" headerTitle="SEA"/>

            <div className="contenido">
                <CioDescriptionEng 
                    des={ [
                        'One sole global ocean exists, covering 70% of the planet.',
                        'Despite its importance for our survival, we know very little about the ocean and its depths. There is much more to explore and learn.',
                    ] }
                />
                <div className="contenido_ops">
                    {MarHabitatsEng.map((habitat) => (
                        <Element link={ habitat.link } nombre={ habitat.nombre } key={ habitat.nombre }/>
                    ))}
                </div>
                <div className="contenido_back"><Link to="/eng/habitats"></Link><img src="/images/tierra/arrow.svg" alt="Flecha atrás"/>Habitats</div>
            </div>

            <FooterEng/>
        </>
    )
}