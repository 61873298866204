import { Navbar, Sidebar } from "../components/Navigation"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { ImageWebp } from "../components/ResponsiveImage"

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

import ex from "../styles/actividades.module.css"
import cx from "classnames"

gsap.registerPlugin(ScrollTrigger);

export default function ActividadesDos() {
    { /* Entradas imagenes */ }
    const titulo = useRef(null);
    const calypso_image = useRef(null);
    const rayas_image = useRef(null);
    const agaponias_image = useRef(null);
    const lobos_image = useRef(null);
    const pinguinos_image = useRef(null);
    const interaccion_pinguinos_image = useRef(null);
    const eventos_image = useRef(null);
    const fiesta_infantil_image = useRef(null);
    const escolares_image = useRef(null);
    
    { /* Entradas description */ }
    const calypso_des = useRef(null);
    const rayas_des = useRef(null);
    const agaponias_des = useRef(null);
    const lobos_des = useRef(null);
    const pinguinos_des = useRef(null);
    const interaccion_pinguinos_des = useRef(null);
    const eventos_des = useRef(null);
    const fiesta_infantil_des = useRef(null);
    const escolares_des = useRef(null);

    { /* Entradas imagenes */ }
    useEffect(() => { gsap.to(titulo.current, { duration: 1.5, opacity: 1, transform: 'translate(0, -100%)', ease: 'circ.out' }); }, []);
    useEffect(() => { gsap.from(calypso_image.current, { duration: 1.5, opacity: 0, y: 60, ease: 'circ.out' , scrollTrigger: '.calypso_image'}); }, []);
    useEffect(() => { gsap.from(rayas_image.current, { duration: 1.5, opacity: 0, y: 60, ease: 'circ.out' , scrollTrigger: '.rayas_image'}); }, []);
    useEffect(() => { gsap.from(agaponias_image.current, { duration: 1.5, opacity: 0, y: 60, ease: 'circ.out' , scrollTrigger: '.agaponias_image'}); }, []);
    useEffect(() => { gsap.from(lobos_image.current, { duration: 1.5, opacity: 0, y: 60, ease: 'circ.out' , scrollTrigger: '.lobos_image'}); }, []);
    useEffect(() => { gsap.from(pinguinos_image.current, { duration: 1.5, opacity: 0, y: 60, ease: 'circ.out' , scrollTrigger: '.pinguinos_image'}); }, []);
    useEffect(() => { gsap.from(interaccion_pinguinos_image.current, { duration: 1.5, opacity: 0, y: 60, ease: 'circ.out' , scrollTrigger: '.interaccion_pinguinos_image'}); }, []);
    useEffect(() => { gsap.from(eventos_image.current, { duration: 1.5, opacity: 0, y: 60, ease: 'circ.out' , scrollTrigger: '.eventos_image'}); }, []);
    useEffect(() => { gsap.from(fiesta_infantil_image.current, { duration: 1.5, opacity: 0, y: 60, ease: 'circ.out' , scrollTrigger: '.fiesta_infantil_image'}); }, []);
    useEffect(() => { gsap.from(escolares_image.current, { duration: 1.5, opacity: 0, y: 60, ease: 'circ.out' , scrollTrigger: '.escolares_image'});}, []);

    { /* Entradas description */ }
    useEffect(() => { gsap.from(calypso_des.current, { duration: 1.5, opacity: 0, x: 60, ease: 'circ.out' , scrollTrigger: '.calypso_des'}); }, []);
    useEffect(() => { gsap.from(rayas_des.current, { duration: 1.5, opacity: 0, x: -60, ease: 'circ.out' , scrollTrigger: '.rayas_des'}); }, []);
    useEffect(() => { gsap.from(agaponias_des.current, { duration: 1.5, opacity: 0, x: 60, ease: 'circ.out' , scrollTrigger: '.agaponias_des'}); }, []);
    useEffect(() => { gsap.from(lobos_des.current, { duration: 1.5, opacity: 0, x: -60, ease: 'circ.out' , scrollTrigger: '.lobos_des'}); }, []);
    useEffect(() => { gsap.from(pinguinos_des.current, { duration: 1.5, opacity: 0, x: 60, ease: 'circ.out' , scrollTrigger: '.pinguinos_des'}); }, []);
    useEffect(() => { gsap.from(interaccion_pinguinos_des.current, { duration: 1.5, opacity: 0, x: -60, ease: 'circ.out' , scrollTrigger: '.interaccion_pinguinos_des'}); }, []);
    useEffect(() => { gsap.from(eventos_des.current, { duration: 1.5, opacity: 0, x: 60, ease: 'circ.out' , scrollTrigger: '.eventos_des'}); }, []);
    useEffect(() => { gsap.from(fiesta_infantil_des.current, { duration: 1.5, opacity: 0, x: -60, ease: 'circ.out' , scrollTrigger: '.fiesta_infantil_des'}); }, []);
    useEffect(() => { gsap.from(escolares_des.current, { duration: 1.5, opacity: 0, x: 60, ease: 'circ.out' , scrollTrigger: '.escolares_des'}); }, []);

    return (
        <>
            <Navbar engLink={ "/eng/activities" }/>
            <Sidebar engLink={ "/eng/activities" }/>
            <Header routeName="actividadesDos" altName="Pecera redonda"/>
                
            {/* ACTIVIDADES */}
            <div className={ ex.actividades }>
                {/* ACTIVIDADES TITLE */}
                <div className={ ex.actividades_title } ref={ titulo }>
                    <div className={ cx(ex.actividades_title_title, 'title') }>ACTIVIDADES</div>
                    <div className={ ex.actividades_title_sep }></div>
                    <div className={ ex.actividades_title_des }>Grandiosas actividades para disfrutar en el Gran Acuario, para toda la familia.</div>
                </div>

                {/* RECORRIDO CALYPSO */}
                <div className={ ex.actividad }>
                    <div className={ cx(ex.actividad_image, 'calypso_image') } ref={ calypso_image }>
                        <ImageWebp route="actividadesDos/calypso" alt="RECORRIDO CALYPSO"/>
                    </div>
                    <div className={ cx(ex.actividad_des, 'calypso_des') } ref={ calypso_des }>
                        <div className={ cx(ex.actividad_des_title, 'title') }>TOUR VIP</div>
                        <div className={ ex.actividad_des_des }>¡Ponte el casco y acompáñanos en este recorrido especial! Aquí conocerás el corazón de Gran Acuario Mazatlán visitando el área de Soporte de Vida. También conocerás a nuestro equipo de buzos y veterinarios y podrás alimentar a las especies del gran hábitat Oceánica.</div>
                        <div className={ ex.actividad_des_des }>Edad: A partir de 12 años<br/>¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                        <div className={ ex.actividad_des_des }>¿Te gustaría realizar este recorrido en grupo?<br/>Comunícate a <a href="mailto:ventasgrupos@granacuario.com">ventasgrupos@granacuario.com</a></div>
                    </div>
                </div>
                {/* ALIMENTACIÓN RAYAS */}
                <div className={ ex.actividad }>
                    <div className={ cx(ex.actividad_des, 'rayas_des') } ref={ rayas_des }>
                        <div className={ cx(ex.actividad_des_title, 'title') }>ALIMENTACIÓN DE RAYAS</div>
                        <div className={ ex.actividad_des_des }>Ven y emociónate en esta divertida experiencia en el hábitat Bahía de Rayas. ¡Tienes que vivirlo!</div>
                        <div className={ ex.actividad_des_des }>Actividad para toda la familia. ¡Consulta nuestros horarios!</div>
                        <div className={ ex.actividad_des_des }>*De venta en nuestras instalaciones</div>
                    </div>
                    <div className={ cx(ex.actividad_image, 'rayas_image') } ref={ rayas_image }>
                        <ImageWebp route="actividadesDos/rayas" alt="ALIMENTACIÓN RAYAS"/>
                    </div>
                </div>
                {/* ALIMENTACIÓN AGAPONIAS */}
                <div className={ ex.actividad }>
                    <div className={ cx(ex.actividad_image, 'agaponias_image') } ref={ agaponias_image }>
                        <ImageWebp route="actividadesDos/agaponias" alt="ALIMENTACIÓN AGAPONIAS"/>
                    </div>
                    <div className={ cx(ex.actividad_des, 'agaponias_des') } ref={ agaponias_des }>
                        <div className={ cx(ex.actividad_des_title, 'title') }>ALIMENTACIÓN DE AGAPORNIS</div>
                        <div className={ ex.actividad_des_des }>Esta es una experiencia llena de colores que no te puedes perder.</div>
                        <div className={ ex.actividad_des_des }>Actividad para toda la familia, ¡Consulta nuestros horarios!</div>
                        <div className={ ex.actividad_des_des }>Porque cuidamos la salud de nuestras especies, esta actividad tiene cupo máximo para 50 personas al día.</div>
                        <div className={ ex.actividad_des_des }>*De venta en nuestras instalaciones</div>
                    </div>
                </div>
                {/* LOBOS MARINOS */}
                <div className={ ex.actividad }>
                    <div className={ cx(ex.actividad_des, 'lobos_des') } ref={ lobos_des }>
                        <div className={ cx(ex.actividad_des_title, 'title') }>CONOCE A LOS LOBOS MARINOS</div>
                        <div className={ ex.actividad_des_des }>Déjate sorprender por su gran energía e increíbles habilidades.</div>
                        <div className={ ex.actividad_des_des }>Actividad para toda la familia, incluida en tu boleto "Visita Gran Acuario Mazatlan LyP".</div>
                        <div className={ ex.actividad_des_des }>¡Consulta nuestros horarios!</div>
                    </div>
                    <div className={ cx(ex.actividad_image, 'lobos_image') } ref={ lobos_image }>
                        <ImageWebp route="actividadesDos/lobos" alt="LOBOS MARINOS"/>
                    </div>
                </div>
                {/* PINGUINOS */}
                <div className={ ex.actividad }>
                    <div className={ cx(ex.actividad_image, 'pinguinos_image') } ref={ pinguinos_image }>
                        <ImageWebp route="actividadesDos/pinguinos" alt="PINGUINOS"/>
                    </div>
                    <div className={ cx(ex.actividad_des, 'pinguinos_des') } ref={ pinguinos_des }>
                        <div className={ cx(ex.actividad_des_title, 'title') }>VISITA EL HÁBITAT DE LOS PINGÜINOS</div>
                        <div className={ ex.actividad_des_des }>Visita el hábitat de los pingüinos de Humboldt, las aves más tiernas y divertidas de Gran Acuario Mazatlán.</div>
                        <div className={ ex.actividad_des_des }> Acceso incluido en tu boleto "Visita Gran Acuario Mazatlan LyP".</div>
                    </div>
                </div>
                {/* INTERACCIÓN PINGUINOS */}
                <div className={ ex.actividad }>
                    <div className={ cx(ex.actividad_des, 'interaccion_pinguinos_des') } ref={ interaccion_pinguinos_des }>
                        <div className={ cx(ex.actividad_des_title, 'title') }>INTERACCIÓN CON PINGÜINOS DE HUMBOLDT</div>
                        <div className={ ex.actividad_des_des }>¿Alguna vez has interactuado con un pingüino? En esta increíble experiencia podrás verlos frente a ti, alimentarlos y tendrás la oportunidad de llevar una linda fotografía.</div>
                        <div className={ ex.actividad_des_des }>Estas adorables aves aman el frío, te recomendamos traer tu abrigo para mayor comodidad. Sujeto a espacio. ¡Consulta nuestros horarios!</div>
                        <div className={ ex.actividad_des_des }>*De venta en nuestras instalaciones</div>
                    </div>
                    <div className={ cx(ex.actividad_image, 'interaccion_pinguinos_image') } ref={ interaccion_pinguinos_image }>
                        <ImageWebp route="actividadesDos/interaccion_pinguinos" alt="INTERACCIÓN PINGUINOS"/>
                    </div>
                </div>
                {/* EVENTOS */}
                <div className={ ex.actividad }>
                    <div className={ cx(ex.actividad_image, 'eventos_image') } ref={ eventos_image }>
                        <ImageWebp route="actividadesDos/eventos" alt="EVENTOS"/>
                    </div>
                    <div className={ cx(ex.actividad_des, 'eventos_des') } ref={ eventos_des }>
                        <div className={ cx(ex.actividad_des_title, 'title') }>EVENTOS CORPORATIVOS</div>
                        <div className={ ex.actividad_des_des }>Nuestro majestuoso edificio cuenta con maravillosos espacios ideales para tus celebraciones más importantes. ¡Experimenta momentos increíbles rodeado de la magia del mar!</div>
                        <div className={ ex.actividad_des_des }>Para más información contáctanos en: <a href="mailto:eventos@granacuario.com">eventos@granacuario.com</a></div>
                    </div>
                </div>
                {/* FIESTA INFANTIL */}
                <div className={ ex.actividad }>
                    <div className={ cx(ex.actividad_des, 'fiesta_infantil_des') } ref={ fiesta_infantil_des }>
                        <div className={ cx(ex.actividad_des_title, 'title') }>FIESTA DE CUMPLEAÑOS</div>
                        <div className={ ex.actividad_des_des }>Los más pequeños podrán celebrar un cumpleaños lleno de diversión y sorpresas, recorriendo el Gran Acuario Mazatlán y finalizando en una increíble fiesta.</div>
                        <div className={ ex.actividad_des_des }>Para más información contáctanos en: <a href="mailto:eventos@granacuario.com">eventos@granacuario.com</a></div>
                    </div>
                    <div className={ cx(ex.actividad_image, 'fiesta_infantil_image') } ref={ fiesta_infantil_image }>
                        <ImageWebp route="actividadesDos/fiesta_infantil" alt="FIESTA INFANTIL"/>
                    </div>
                </div>
                {/* VISITAS ESCOLARES */}
                <div className={ ex.actividad }>
                    <div className={ cx(ex.actividad_image, 'escolares_image') } ref={ escolares_image }>
                        <ImageWebp route="actividadesDos/escolares" alt="VISITAS ESCOLARES"/>
                    </div>
                    <div className={ cx(ex.actividad_des, 'escolares_des') } ref={ escolares_des }>
                        <div className={ cx(ex.actividad_des_title, 'title') }>VISITAS ESCOLARES</div>
                        <div className={ ex.actividad_des_des }>Porque aprender es más divertido bajo el agua. Planea con nosotros tu recorrido escolar y explora el mundo marino en nuestra Experiencia cardumen.</div>
                        <div className={ ex.actividad_des_des }>Contamos con un recorrido especial para cada grado escolar, pregunta por las opciones que tenemos para ti.</div>
                        <div className={ ex.actividad_des_des }>Agenda tu recorrido en: <a href="mailto:gruposescolares@granacuario.com">gruposescolares@granacuario.com</a></div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}