import { NavbarEng, SidebarEng } from "../components/Navigation"
import Header from "../components/Header"
import FooterEng from "../components/FooterEng";

import { Accordion } from "react-accessible-accordion"
import { Pregunta, PreguntaAccordion } from "../components/FaqElements";

import faq from './../styles/faq.module.css'
import cx from 'classnames'

export default function FaqEng() {
    return(
        <>
            <NavbarEng espLink={ "/faq" }/>
            <SidebarEng espLink={ "/faq" }/>
            <Header routeName="habitats" altName="Niño mirando pecera" headerTitle="FAQs" titleClasses="default"/>

            {/* Preguntas frecuentes
            <div className={ cx(faq.preguntas, 'section') }>
                <div className={ cx(faq.preguntas_content, 'content') }>
                    <Pregunta pregunta="¿Puedo tomar fotografías?" respuesta="Puedes tomar todas las fotografías que quieras sin hacer uso de flash y sin ningún costo extra. No se permite el ingreso con equipo extra como trípie, lentes, etc."/>
                    <Pregunta pregunta="¿Cuentan con estacionamiento?" respuesta="Frente al Gran Acuario contamos con estacionamiento. Tiene un costo de $50.00 de lunes a jueves y de $ 60.00 de viernes a domingo."/>
                    <Pregunta pregunta="¿Puedo ingresar con alimentos?" respuesta="Por seguridad e higiene, no se permite la entrada con alimentos ni bebidas."/>
                    <Pregunta pregunta="¿Puedo llevar carreola para un bebé?" respuesta="Sí están permitidas las corriolas sencillas, no las carriolas dobles."/>
                    <Pregunta pregunta="¿Tienen lockers?" respuesta="Por lo pronto no tenemos lockers, por ello, no está permitido ingresar con bolsas y/o mochilas."/>
                    <Pregunta pregunta="¿Cuáles son sus horarios?" respuesta="Horarios: de lunes a domingo de 10 a 18 horas."/>
                </div>
            </div>
            */}

            <div className={ cx(faq.preguntas, 'section') }>
                <div className={ cx(faq.preguntas_content, 'content') }>
                    <div className={ faq.preguntas_title }>Do you have any questions about the Gran Aquario Mazatlán? Here are some of the most frequently asked questions. If you don´t find your answer, contact us through WhatsApp at <a href="tel:6699817815"> +52 (669) 139 0134.</a></div>
                    <Accordion className={ faq.faq_accordion }>
                        <PreguntaAccordion pregunta="Can I take photographs?" respuesta="You can take as many pictures as you want without the use of flash and at no extra cost. Extra equipment such as tripods, lenses, etc. are not allowed."/>
                        <PreguntaAccordion pregunta="Do you have parking?" respuesta="Parking is available in front of the Grand Aquarium. It has a cost of $50.00 from Monday to Thursday and $ 60.00 from Friday to Sunday."/>
                        <PreguntaAccordion pregunta="Can I introduce food and/or beverages into the premises?" respuesta="For safety and hygiene reasons, food and beverages are not allowed."/>
                        <PreguntaAccordion pregunta="Can I bring a stroller?" respuesta="Single strollers are allowed, not double strollers."/>
                        <PreguntaAccordion pregunta="Do you have lockers?" respuesta="For the moment we do not have lockers, therefore, it is not allowed to enter with bags and/or backpacks."/>
                        <PreguntaAccordion pregunta="What are your operating hours?" respuesta="Hours: Monday to Sunday from 10:00 am to 6:00 pm. The ticket office closes at 4 pm."/>
                    </Accordion>
                </div>
            </div>

            <FooterEng/>
        </>
    )
}