import ex from "../styles/mapa.module.css"
import cx from "classnames"

export function MapaEng() {
    return (
        <>
            <img src="/images/mapas/mapa_eng.png" srcSet="/images/mapas/mapa_eng.webp"/>
        </>
    )
}

export function MapaEngEscaleras() {
    return (
        <>
            <img src="/images/mapas/mapa_escaleras.png" srcSet="/images/mapas/mapa_escaleras.webp"/>
        </>
    )
}

export function MapaEngTunel() {
    return (
        <>
            <img src="/images/mapas/mapa_tunel.png" srcSet="/images/mapas/mapa_tunel.webp"/>
        </>
    )
}