import { Link } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

import { Navbar, Sidebar } from "../components/Navigation"
import Header from "../components/Header"
import Footer from "../components/Footer";

import "../styles/global.css"
import "./../styles/exhibiciones.css"

gsap.registerPlugin(ScrollTrigger);

export default function Exhibiciones() {

    { /* Entradas de texto */ }
    const contAnim1 = useRef(null);
    const contAnim2 = useRef(null);
    const contAnim3 = useRef(null);
    const contAnim4 = useRef(null);
    useEffect(() => {
        gsap.from(contAnim1.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.contAnim1' });
    }, []);
    useEffect(() => {
        gsap.from(contAnim2.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.contAnim2' });
    }, []);
    useEffect(() => {
        gsap.from(contAnim3.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.contAnim3' });
    }, []);
    useEffect(() => {
        gsap.from(contAnim4.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.contAnim4' });
    }, []);

    { /* Entradas de bloques */ }
    const exAnim1 = useRef(null);
    const exAnim2 = useRef(null);
    const exAnim3 = useRef(null);
    useEffect(() => {
        gsap.from(exAnim1.current, { duration: 1, y: 40, opacity: 0, scrollTrigger: '.exAnim1' });
    }, []);
    useEffect(() => {
        gsap.from(exAnim2.current, { duration: 1, y: 40, opacity: 0, scrollTrigger: '.exAnim2' });
    }, []);
    useEffect(() => {
        gsap.from(exAnim3.current, { duration: 1, y: 40, opacity: 0, scrollTrigger: '.exAnim3' });
    }, []);

    return (
        <>
            <Navbar engLink={ "/eng/habitats" }/>
            <Sidebar engLink={ "/eng/habitats" }/>
            <Header routeName="habitats" altName="Niño mirando pecera" headerTitle="HÁBITATS" titleClasses="habitats"/>

            { /* Descripción */ }
            <div className="contenido section">
                <div className="content">
                    <div className="contenido__el">
                        <div className="contenido__el__t1 title contAnim1" ref={ contAnim1 }>EL ACUARIO,</div>
                        <div className="contenido__el__t1 title contAnim2" ref={ contAnim2 }>DEL ACUARIO DEL MUNDO<img src="/images/habitats/arrow.svg" alt="Arrow"/></div>
                    </div>
                    <div className="contenido__el">
                        <div className="contenido__el__t2 contAnim3" ref={ contAnim3 }>Celebrando la diversidad del Mar de Cortés, llamado el Acuario del Mundo por el reconocido explorador Jacques-Yves Cousteau, el Gran Acuario Mazatlán presenta una colección única en el mundo, dedicada al Mar de Cortés.</div>
                        <div className="contenido__el__t2 contAnim4" ref={ contAnim4 }>Con casi 5 millones de litros de agua salada en sus diferentes exhibiciones, los visitantes podrán conocer especies maravillosas del mar abierto, arrecifes y costas.</div>
                    </div>
                </div>
            </div>

            { /* Exhibiciones elementos */ }
            <div className="exhibiciones">
                <div className="exhibiciones_con">
                    <div className="exhibiciones_con__el exAnim1" ref={ exAnim1 }>
                        <Link to="costa" className="exhibiciones_con__el_btn"></Link>
                        <div className="exhibiciones_con__el_title">Costa<img src="/images/habitats/info.svg" alt="Info icon"/></div>
                        <img src="/images/habitats/costa.png" alt="Costa"/>
                    </div>
                    <Link to="/" className="exhibiciones_con__btn"><img src="/images/habitats/arrow.svg" alt="Arrow"/>Inicio</Link>
                </div>
                <div className="exhibiciones_con">
                    <div className="exhibiciones_con__el exAnim2" ref={ exAnim2 }>
                        <Link to="tierra" className="exhibiciones_con__el_btn"></Link>
                        <div className="exhibiciones_con__el_title">Tierra<img src="/images/habitats/info.svg" alt="Info icon"/></div>
                        <img src="/images/habitats/tierra.png" alt="Tierra"/>
                    </div>
                    <div className="exhibiciones_con__el exAnim3" ref={ exAnim3 }>
                        <Link to="mar" className="exhibiciones_con__el_btn"></Link>
                        <div className="exhibiciones_con__el_title">Mar<img src="/images/habitats/info.svg" alt="Info icon"/></div>
                        <img src="/images/habitats/mar.png" alt="Mar"/>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}